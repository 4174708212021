//paragraph__semantic_layer_banner

import React from "react";

const ParagraphSemanticLayerBanner = ({data}) => {

  const {
    title,
    description,
    label,
    //images,
    //background,
    className = "text-white",
    ...props
  } = data

//   const data1 = {
//     title: props.title,
//     label: props.label,
//     description: props.description,
//     // images: props.relationships.images.map((img, index) => ({
//     //   src: img.localFile.publicURL,
//     //   alt: props.images[index].alt,
//     // })),
//     //background: props.relationships.background.localFile.publicURL,
//   };
const images = props.relationships.images.map((img, index) => ({
    src: img.localFile.publicURL,
    alt: props.images[index].alt,
  }))
const background = props.relationships.background.localFile.publicURL

  const big = images[2]
  const small = [images[0], images[1]]
  return (

      <div
        className={"  rounded-[30px] bg-no-repeat bg-cover bg-center	 " + className}
        style={{ backgroundImage: `url("${background}")` }}
      >
        

        <div className="flex flex-row justify-between py-12 lg:py-[82px] px-8 lg:pl-[70px] lg:pr-[40px]">
          <div className="lg:w-[892px] flex flex-col space-y-10">
            <div className="text-2xl lg:text-[72px] font-medium   tracking-[-0.03em] lg:leading-[76px] max-w-[594px]">
              {title}
            </div>
            <div className="flex flex-col lg:flex-row justify-start gap-x-10">
              <div className="text-lg leading-[28px] max-w-[611px] mb-12 lg:mb-0">
                {description}
              </div>
              <div className="flex flex-row gap-x-6 items-center ">
                {small.map((image, index) => (
                  <div key={index} className="">
                    <img src={image.src} alt={image.alt} className="block" />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
          <div  className="hidden lg:block">
              <img src={big.src} alt={big.alt} className="block " />
            </div>
          </div>
        </div>
      </div>

  );
};

export default ParagraphSemanticLayerBanner;
