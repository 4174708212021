import React, { useState, useRef, useEffect } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import VisibilitySensor from "react-visibility-sensor";
import playIcon from "../images/play-circle.svg";
import YouTube from "react-youtube";
import { BrowserView, MobileView } from "react-device-detect";
import ParagraphSemanticLayerBanner from "../components/paragraphs/semantic-layer-banner";
import ParagraphCtas from "../components/paragraphs/ctas";

//const isProduction = `${process.env.GATSBY_ACTIVE_ENV}` === "production";
const videoId = "1ZvzjvLh7G4";

function Platform2Page({ data: { node }, path, ...props }) {
  const {
    //title,
    hero_title,
    hero_description,
    icons_title,
    icons_description,
    icons_alts,
    features_title,
    //how_it_works_title
  } = node;

  //console.log( path )
  const {
    hero_images, // "paragraph__title_text_image"
    blocks, // "paragraph__title_text_image"
    icons_images,
    features, // "paragraph__title_text_image"
    banner,
    ctas
    /*, how_it_works*/
  } = node.relationships;

  //console.log(ctas)

  const seo = getMetatags(
    node.metatag,
    node.relationships.OGImage?.localFile?.publicURL
  );

  const hero = {
    title: hero_title,
    description: hero_description,
    sections: hero_images,
  };

  //hero.sections[1].image = hero.sections[0].image;
  //hero.sections[1].relationships = hero.sections[0].relationships;

  //console.log( hero.sections)

  const icons = {
    title: icons_title,
    description: icons_description,
    images: icons_images.map((img, index) => ({
      src: img?.localFile?.publicURL,
      alt: icons_alts[index].alt,
    })),
  };

  //console.log(icons);

  return (
    <Layout showCta={false}>
      <Seo {...seo} />
      <div className="h-[70px]"></div>
      <Hero {...hero} path={path} >
        <ParagraphCtas data={ctas} />
      </Hero>
      <Section4 title={"my title"} items={blocks} />
      <SectionIcons {...icons} />
      {/* <SectionHowItWorks title={how_it_works_title} items={how_it_works} /> */}
      <SectionFeatures items={features} title={features_title} />

      <div className="py-0 lg:py-[120px]">
        <div className="max-w-screen-xl mx-auto lg:h-[412px]">
          <ParagraphSemanticLayerBanner data={banner} />
        </div>
      </div>

      <Cta
        title={"Get started with Sightfull"}
        link={{ uri: "/book-a-demo/", title: "Book a Demo" }}
      />
    </Layout>
  );
}

const Hero = ({ title, description, sections, path, children, ...props }) => {
  return (
    <section className="mt-12 lg:mt-[74px] md:pb-[120px] px-6 lg:px-0">
      <div className="max-w-screen-xl mx-auto space-y-[102px]">
        <div className="space-y-8">
          <div className="">
            <h1
              className="text-center  text-[40px] lg:text-[76px] font-semibold leading-[44px] lg:leading-[84px] lg:tracking-[-0.03em] text-[#3D434C]"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>

          <div
            className="text-center text-[20px] leading-[32px] text-[#3D434C]"
            dangerouslySetInnerHTML={{
              __html: description.replace("  ", "<br />"),
            }}
          />

          <div>
            {children}
          </div>

          {path === "/platform/" && <HeroTabs sections={sections} />}
          {path !== "/platform/" && <HeroImage sections={sections} />}
        </div>
      </div>
    </section>
  );
};

const HeroTabs = ({ sections }) => {
  const ref = useRef();
  const [pos, setPos] = useState(2);
  const [selectedVideo, setSelectedVideo] = useState(false);

  const openModal = (item) => {
    setSelectedVideo(videoId /*"/Platform-Full.mp4"*/);
  };

  const closeModal = () => {
    setSelectedVideo(false);
  };

  const handleClick = (e) => {
    const newPos = parseInt(e.target.id.split("-")[1]);
    setPos(newPos);

    ref.current.style.left =
      e.target.getBoundingClientRect().left -
      e.target.parentElement.getBoundingClientRect().left +
      "px";
    ref.current.style.width = e.target.getBoundingClientRect().width + "px";
  };

  useEffect(() => {
    const el = document.getElementById("show-2");

    ref.current.style.left =
      el.getBoundingClientRect().left -
      el.parentElement.getBoundingClientRect().left +
      "px";
    ref.current.style.width = el.getBoundingClientRect().width + "px";
  }, []);

  const handleVideoClick = () => {
    openModal();
  };

  return (
    <div className="space-y-[102px]">
      <VideoModal video={selectedVideo} close={closeModal}>
        <VideoModalContent video={selectedVideo} close={closeModal} />
      </VideoModal>

      <div className="">
        <div className=" flex flex-row  justify-center ">
          <div className=" flex flex-row justify-center gap-x-2 border rounded-[74px] p-1 border-[#3D434C] relative ">
            {sections.map((section, index) => (
              <button
                key={index}
                className={
                  pos === index + 1
                    ? " px-5 py-[13px] text-white     duration-1000"
                    : " px-5 py-[13px] text-[#3D434C] duration-1000"
                }
                id={`show-${index + 1}`}
                onClick={handleClick}
              >
                {section.label}
              </button>
            ))}
            <div
              className="-z-[1] border border-[#3D434C] rounded-[74px]   bg-[#3D434C] text-white rounded-xl absolute bottom-0 top-0  my-1  duration-1000 "
              ref={ref}
            ></div>
          </div>
        </div>
      </div>

      <div className="max-w-[1086px] mx-auto ">
        {sections.map((section, index) => (
          <div
            className={
              pos === index + 1
                ? " text-center h-auto opacity-100 duration-1000 relative  "
                : " text-center h-0 opacity-0 duration-1000 "
            }
            key={index}
          >
            <img
              className="mx-auto block  text-center "
              src={section?.relationships?.image?.localFile?.publicURL}
              alt={`${section?.image?.alt}`}
              style={{
                filter:
                  index === 1
                    ? "drop-shadow(0px 1.852px 3.148px rgba(0, 0, 0, 0.01)) drop-shadow(0px 8.148px 6.519px rgba(0, 0, 0, 0.01)) drop-shadow(0px 20px 13px rgba(0, 0, 0, 0.01)) drop-shadow(0px 38.519px 25.481px rgba(0, 0, 0, 0.02)) drop-shadow(0px 64.815px 46.852px rgba(0, 0, 0, 0.02)) drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.03))"
                    : "",
              }}
            />
            {index === 1 && pos === 2 && (
              <div className="absolute bottom-[-50%] lg:top-[30%] left-0 right-0">
                <button
                  className="bg-[#1A1D21] hover:bg-[#2D3238] px-5 py-2.5 rounded-[10px] text-white "
                  style={{ boxShadow: "0px 4px 14px 0px #00000073" }}
                  onClick={handleVideoClick}
                >
                  <span className="mr-2.5">
                    <img src={playIcon} alt="play" className="inline" />
                  </span>
                  <span className="mr-2">Watch demo</span>
                  <span className="text-[#9DA4B0]">(2 min)</span>
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const HeroImage = ({ sections }) => {


  const desktop = sections[0];
  const mobile = sections[1] || false ;
  
  return (
    <div className="md:space-y-[102px]">
      <div />

      <div className="max-w-[1086px] mx-auto hidden md:block">
        
          <div
            className={
              " text-center h-auto opacity-100 duration-1000 relative  "
            }

          >
            <img
              className="mx-auto block  text-center "
              src={desktop?.relationships?.image?.localFile?.publicURL}
              alt={`${desktop?.image?.alt}`}
            />
          </div>
        
      </div>

      { mobile && <div className=" mx-auto md:hidden ">
        
          <div
            className={
              " text-center h-auto opacity-100 duration-1000 relative  "
            }

          >
            <img
              className="mx-auto block  text-center "
              src={mobile?.relationships?.image?.localFile?.publicURL}
              alt={`${mobile?.image?.alt}`}
            />
          </div>
        
      </div>
}

    </div>
  );
};

const VideoModal = ({ video, children }) => {
  //console.log('video', video);
  return !video ? false : <>{children}</>;
};

const VideoModalContent = ({ video, close, children }) => {
  const onReady = (player) => {
    //player.playVideo();
  };

  return (
    <div
      className={
        "modal fixed w-full h-full top-0  left-0 flex items-center justify-center z-50 block opacity-100 "
      }
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={close}
        onKeyDown={close}
        role="presentation"
      ></div>

      <div
        className="--bg-white --w-11/12 lg:max-w-[1224px] --lg:max-h-[800px] mx-auto rounded "
        style={{ zIndex: "150" }}
      >
        <div className="modal-content  ">
          <div className="block">
            <BrowserView>
              <Video
                video_id={videoId}
                onReady={onReady}
                width="800"
                height="450"
              />
            </BrowserView>
            <MobileView>
              <Video video_id={videoId} onReady={onReady} width="100%" />
            </MobileView>
          </div>
        </div>
      </div>
    </div>
  );
};

const Video = ({
  video_id,
  onReady,
  className = "",
  width = "",
  height = "",
}) => {
  //console.log(video_id);
  const opts = {
    // height: "100%",
    width: width, // "100%",
    height: height,
    // width: "840",
    /*height: "450",*/
    /*width: "800",*/
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      loop: 0,
      rel: 0,
    },
  };

  return (
    <YouTube
      videoId={video_id}
      opts={opts}
      className={className + " "}
      /*style={{width: '100% !important'}}*/
      onReady={(event) => onReady(event.target)}
    />
  );
};

const SectionIcons = ({ title, description, images, ...props }) => {
  //console.log(props);

  return (
    <div className="max-w-screen-xl mx-auto px-6 lg:px-0 py-12 lg:py-[120px] space-y-8 text-[#3D434C]">
      <h2
        className="font-medium text-[35px] lg:text-[72px] leading-[42px] lg:leading-[76px] tracking-[-0.03em]"
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <div className="font-lg leading-[28px] tracking-[-0.01em] max-w-[740px]">
        {description}
      </div>
      <div className="flex flex-wrap lg:flex-nowrap	gap-6 lg:gap-0 flex-row justify-between">
        {images.map((image, index) => (
          <div key={index}>
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </div>
      <div>
        <Link to={"/integrations/"}>See all integrations --></Link>
      </div>
    </div>
  );
};

const SectionFeatures = ({ title, items, images, ...props }) => {
  //console.log(props);

  return (
    <div className="bg-[#F9F8F7] py-[50px] lg:py-[120px] px-5 lg:px-0">
      <div className="max-w-screen-xl mx-auto text-[#3D434C] space-y-10 lg:space-y-16">
        <h2
          className="font-medium text-[40px] lg:text-[72px] leading-[42px] lg:leading-[76px] tracking-[-0.03em]"
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-[60px] gap-x-[85px]">
          {items.map((item, index) => (
            <div className="pl-8 border-l space-y-[60px]" key={index}>
              <div>
                <img
                  src={item?.relationships?.image?.localFile?.publicURL}
                  alt={`${item?.image?.alt}`}
                  className="h-8 w-8"
                />
              </div>
              <div className="space-y-2">
                <h3 className="font-semibold text-lg leading-[28px] tracking-[-0.01em]">
                  {item.title}
                </h3>
                <div className="text-lg leading-[28px] tracking-[-0.01em]">
                  {item.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Section4 = ({ title, items, ...props }) => {
  //console.log(items, props);

  const rows = items.map((item, index) => {
    const b1 = (
      <div className="lg:max-w-[600px] mr-auto">
        <div className="space-y-[25px] lg:space-y-8 lg:max-w-[540px] ">
          <h3 className="text-[25px] leading-[29px] lg:text-[36px] lg:leading-[40px] tracking-[-0.03em] ">
            {item.title}
          </h3>
          <div
            className="text-lg leading-[28px] tracking-[-0.01em] "
            dangerouslySetInnerHTML={{
              __html: item?.description,
            }}
          />
        </div>
      </div>
    );

    const b2 = (
      <>
        <Appear>
          <div
            className={
              "hidden lg:block max-w-[600px]" //+
              //(index % 2 === 0 ? "pl-16 bg-left-top" : "pr-16 bg-right-top")
            }
            //   style={{
            //     backgroundImage: `url('${item?.relationships?.background?.localFile?.publicURL}')`,
            //   }}
          >
            <img
              src={item?.relationships?.image?.localFile?.publicURL}
              alt={`${item?.image?.alt}`}
              className=" rounded-[20px] "
            />
          </div>

          <div className="lg:hidden mt-[25px]">
            {/* eslint-disable jsx-a11y/media-has-caption */}
            <img
              src={item?.relationships?.image?.localFile?.publicURL}
              alt={`${item?.image?.alt}`}
              className=" rounded-[20px] "
            />

            {/* eslint-disable jsx-a11y/media-has-caption */}
          </div>
        </Appear>
      </>
    );

    return (
      <div
        key={index}
        className={
          "max-w-screen-xl mx-auto flex  lg:flex-row justify-between items-center lg:gap-x-[80px] " +
          (index % 2 !== 0 ? "flex-col-reverse" : "flex-col")
        }
      >
        {index % 2 === 0 ? (
          <>
            {b1}
            {b2}
          </>
        ) : (
          <>
            {b2}
            {b1}
          </>
        )}
      </div>
    );
  });
  return (
    <section className="py-[50px] lg:pt-24 lg:pb-[144px] bg-[#F9F8F7] text-[#3D434C]">
      <div className="container space-y-12 lg:space-y-48">
        {/* <h2 className="text-3xl lg:text-[46px] font-semibold mx-auto text-center">
          {title}
        </h2> */}
        <div className="space-y-[100px] lg:space-y-[80px]">{rows}</div>
      </div>
    </section>
  );
};

const Cta = ({ title, link }) => {
  return (
    <div className="py-12 lg:py-[120px] px-6 lg:px-0">
      <div className="  text-[#3D434C]  max-w-screen-xl mx-auto">
        <div className="container ">
          <div className="space-y-8">
            <div
              className="lg:leading-[84px] tracking-[-0.03em] text-3xl lg:text-[76px] font-semibold  mx-auto text-center"
              dangerouslySetInnerHTML={{ __html: title }}
            />

            {/* background: linear-gradient(286.05deg, #4A72FF 6.93%, #1B4DFF 67.1%); */}

            <div className="text-center">
              <Link
                to={link.uri}
                //className="inline-block text-base font-medium border leading-[20px] rounded-[12px] py-5 px-12 text-white bg-[#1B4DFF] hover:bg-[#0F3CD9] boottom_cta_demo"
                className="border  rounded-lg px-[30px] py-3 font-medium leading-[20px]  text-[#3D434C] hover:bg-[#F7F9FA] boottom_cta_demo"
              >
                {link.title}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Appear = ({ children, delay = 0 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const onChange = (flag) => {
    if (!isVisible) {
      setIsVisible(flag);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <div
        className={isVisible ? " opacity-100 " : " opacity-0 "}
        style={{
          transition: "all",
          //transitionProperty:"all",
          transform: isVisible ? "translateY(0)" : "translateY(100px)",
          transitionDuration: "1000ms",
          transitionDelay: `${delay * 200}ms`,
        }}
      >
        <div className=" ">{children}</div>
      </div>
    </VisibilitySensor>
  );
};

// const SectionBanner = ({ ...props }) => {
//   const data = {
//     title: props.title,
//     label: props.label,
//     description: props.description,
//     images: props.relationships.images.map((img, index) => ({
//       src: img.localFile.publicURL,
//       alt: props.images[index].alt,
//     })),
//     background: props.relationships.background.localFile.publicURL,
//   };

//   return (
//     <div className="py-0 lg:py-[120px]">
//       <div className="max-w-screen-xl mx-auto lg:h-[412px]">
//         <Banner {...data} />
//       </div>
//     </div>
//   );
// };

export default Platform2Page;

export const query = graphql`
  query platform2($id: String!) {
    node: nodePlatform2(id: { eq: $id }) {
      id
      title
      hero_title: field_title
      hero_description: field_description
      icons_title: field_title2
      icons_description: field_description2
      icons_alts: field_logos {
        alt
        title
      }
      features_title: field_title3
      path {
        alias
      }
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      how_it_works_title: field_title4
      relationships {
        OGImage: field_image {
          localFile {
            publicURL
          }
        }
        ctas: field_ctas {
          __typename
          description: field_description
          ctas: field_ctas {
            title
            uri
          }
          active:field_active
        }
        hero_images: field_paragraph_repeter {
          __typename
          label: field_label
          image: field_image {
            alt
            title
          }
          relationships {
            image: field_image {
              localFile {
                publicURL
              }
            }
          }
        }
        blocks: field_paragraphs_blocks {
          ...ParagraphTitleTextImage
        }
        icons_images: field_logos {
          localFile {
            publicURL
          }
        }
        features: field_paragraph_multi {
          ...ParagraphTitleTextImage
        }
        banner: field_cta {
          __typename
          title: field_title
          description: field_description
          label: field_label

          images: field_images {
            alt
          }
          relationships {
            images: field_images {
              localFile {
                publicURL
              }
            }
            background: field_background_image {
              localFile {
                publicURL
              }
            }
          }
        }
        how_it_works: field_items {
          __typename
          title: field_title
          description: field_description
          image: field_image {
            alt
          }

          relationships {
            image: field_image {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
