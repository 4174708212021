import React from "react";
import { Link } from "gatsby";
import TryOut from "../buttons/tryout";

const ParagraphCtas = ({ data, context, ...props }) => {

  if (!data) {
    return false;
  }

  const { active, description, ctas } = data;


  if (!active) {
    return false;
  }


  return (
    <div className="py-8 md:space-y-[60px]">
      <div className="space-y-5">
        <div className=" flex flex-row justify-center items-center gap-2.5 md:gap-4">
          {ctas &&
            ctas.map((item, index) => (
              <Button key={index} index={index} {...item} />
            ))}
        </div>
        <div>
          <div className="text-[#727C8D] text-sm leading-[14px] text-center">
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

const Button = ({ title, index, uri, ...props }) => {
  const url = uri.replace("internal:/", "/");

  if (index === 0) {
    return (
      <TryOut
        title={title}
        className="text-base  font-medium py-3 px-[30px] rounded-lg leading-[20px] whitespace-nowrap dark:hidden bg-[#325FFF] hover:bg-[#1B4DFF]  text-white border border-[#4A72FF] hover:border-[#3D434C88] demo_account try_it_free_hero "
        check={false}
      />
    );
  }

  return (
    <Link
      to={url}
      className="border  rounded-lg px-[30px] py-3 font-medium leading-[20px]  text-[#2D3643] bg-white  --hover:bg-[#F7F9FA] hover:bg-[#F0F3F9]"
      style={{
        boxShadow:
          "0px 0.14px 2.29px 0px #1A1D2108, 0px 0.37px 4.43px 0px #1A1D210C, 0px 3px 7px 0px #1A1D2117",
      }}
    >
      {title}
    </Link>
  );
};
export default ParagraphCtas;
